<template>
    <div class="main-pdding">
        <div class="main-box">

            <el-form inline :model="searchData" label-width="80px">
                <el-form-item label="商城：">
                    <el-input v-model="searchData.name" placeholder="请输入名称或备注" suffix-icon="el-icon-search" @change="searchInfo">
                    </el-input>
                </el-form-item>
                <el-form-item label="有效期：">
                    <el-select v-model="searchData.status" placeholder="请选择" @change="searchInfo">
                        <el-option v-for="(item,index) in validity" :label="item.label" :value="item.value"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="addInfo()"> 添加小程序商户</el-button>
                </el-form-item>
            </el-form>


            <appletDisplay :getApp="getApp" :appData="appData"></appletDisplay>

            <div class="info-page">
                <infoPage :pagination="pagination" @pageclcik="pageclcik"></infoPage>
            </div>
        </div>
        <editpop title='添加商城' :dialogVal="addDialog" @dialogChange="addChange"></editpop>
    </div>
</template>

<script>
    import appletDisplay from './components/appletDisplay.vue'
    import editpop from './components/editpop.vue'
    export default {
        components: {
            appletDisplay,
            editpop
        },
        mounted() {
            this.getApp()
        },
        data() {
            return {
                searchData: {
                    status: '0',
                    name: ''
                },
                validity: [{
                        label: '全部',
                        value: '0'
                    }, {
                        label: '未到期',
                        value: '1'
                    },
                    {
                        label: '已到期',
                        value: '2'
                    }
                ],
                appData: [],
                pagination:{
                    currentpage:1,
                    total:1,
                    totalpage:1,
                    pageSize:8
                },
                addDialog:false, //添加弹框
            }
        },
        methods: {
            searchInfo(){
                 this.pagination.currentpage =1
                 this.getApp()
            },
            // 获取小程序商户
            getApp() {
                const that = this 
                that.$request({
                    url: that.$api.appMall.getApp,
                    method: 'post',
                    data: {
                        status: parseInt(that.searchData.status),
                        name: that.searchData.name,
                        page: that.pagination.currentpage
                    }
                }).then(res => {
                    console.log(res);
                    that.pagination.currentpage = res.data.current_page
                    that.pagination.total = res.data.total
                    that.pagination.totalpage = res.data.last_page
                    that.pagination.pageSize = res.data.per_page
                    that.appData = res.data.data
                })
            },
            pageclcik(e){
                this.pagination.currentpage = e
                this.getApp()
            },
            // 添加小程序商户
            addInfo(){
                this.addDialog = true
            },
            addChange(type,data){
                if (type === 1) {
                    this.addDialog = false
                    this.$request({
                        url: this.$api.appMall.addapp,
                        method: 'post',
                        data: {
                            name:data.name,
                            status:1,
                            remarks:data.remarks
                        }
                    }).then(res => {
                        this.getApp()
                    })
                } else if (type === 2) {
                    this.addDialog = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-page{
        margin-top: 25px;
    }
</style>