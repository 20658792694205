<template>
    <div>
        <el-dialog :visible.sync="dialogVal" width="25%" center :show-close="false" @closed="cancel">
            <span slot="title" class="title">
                <span class="title-text">{{ title }}</span>
                <img class="close-icon" src="@/assets/images/icon/close-black.png" @click="cancel" />
            </span>
            <div class="content">
                <el-form :hide-required-asterisk="true" ref="editref" :model="editform" label-width="80px" :rules="rules">
                    <el-form-item label="商城名称" prop="mallName">
                        <el-input v-model="editform.mallName"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" prop="mallRemarks">
                        <el-input v-model="editform.mallRemarks"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="有效期至">
                        <el-date-picker
                            style="float: left"
                            v-model="editform.mallValid"
                            type="datetime"
                            placeholder="选择账户有效期"
                            :disabled="timeDisabled(checked)"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                        <el-checkbox v-model="checked">永久</el-checkbox>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import rules from "@/utils/rules.js";
export default {
    props: {
        dialogVal: {
            type: Boolean,
            default: false,
        },
        editData: {
            type: Object,
            default: function () {
                return {};
            },
        },
        title: {
            type: String,
            default: "编辑商城",
        },
    },
    data() {
        return {
            rules: rules,
            // checked: false,
            editform: {
                mallName: "",
                // mallValid: "",
                mallRemarks: "",
            },
            mallId: "",
        };
    },
    watch: {
        editData(newVal, oldVal) {
            console.log(newVal,'newVal');
            this.editform.mallName = newVal.name;
            this.mallId = newVal.id;
            this.editform.mallRemarks = newVal.remarks;
            // if (newVal.time === "永久") {
            //     this.checked = true;
            // } else {
            //     this.editform.mallValid = newVal.time;
            // }
        },
    },
    methods: {
        confirm() {
            const that = this;
            const data = {};

            // if (this.checked) {
            //     data.time = "永久";
            // } else {
            //     data.time = that.editform.mallValid;
            // }
            data.remarks = this.editform.mallRemarks;
            data.name = that.editform.mallName;
            data.id = parseInt(that.mallId);
            that.$refs.editref.validate((valid) => {
                if (valid) {
                    if (that.checked == false && that.editform.mallValid == '') {
                        that.$alert("有效期必选", "", {});
                        return;
                    }
                    that.$emit("dialogChange", 1, data);
                    that.editform = {
                        mallName: "",
                        // mallValid: "",
                        mallRemarks: "",
                    };
                    that.checked = false;
                }
            });
        },
        cancel() {
            this.$emit("dialogChange", 2);
        },
    },
    computed: {
        timeDisabled() {
            return function (checked) {
                if (checked) {
                    return true;
                } else {
                    return false;
                }
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    .title-text {
        float: left;
        font-weight: 600;
        font-size: 20px;
    }

    .close-icon {
        float: right;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.content {
    color: #333333;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

::v-deep .el-dialog {
    border-radius: 16px;
}

// ::v-deep .el-dialog__wrapper {
//     display: flex;
//     align-items: center;
// }
</style>
