<template>
    <div class="mall-app dir-left-wrap">
        <div class="app-mall" v-for="(item,index) in appData" :key="index">
            <div class="mall-top main-between">
                <div :class="[{'unexpired':item.last_status=='未到期'},{'expired':item==item.last_status=='已到期'}]">
                    {{item.last_status}}
                </div>
                <div :class="[{'disable-btn':item.status===1},{'start-up':item.status===0}]" @click="delInfo(item)">
                    {{item.status===1?'禁用':'启用'}}
                </div>
            </div>
            <div class="mall-text">
                <div>
                    商城名称：{{item.name}}
                </div>
                <div>
                    备注：{{item.remarks}}
                </div>
                <div>
                    有效期至：{{item.time}}
                </div>
            </div>
            <div class="enter-btn main-center">
                <div class="btn-start" v-if="item.status === 1" @click="goMerchant(item)"> 进入平台</div>
                <div class="btn-disable" v-else> 进入平台</div>
            </div>
            <div class="mall-bommtn main-center">
                <div class="operation" @click="editInfo(item)">
                    <span>编辑</span>
                </div>
                <div class="operation" @click="recInfo(item)">
                    <span>回收</span>
                </div>
                <!-- <div class="operation">
                    <span>版权</span>
                </div> -->
            </div>
        </div>
        <deldialog :appId="appId" title="确定要禁用或启用该商城吗?" @dialogChange="delChange" :dialogVal="delDialog"></deldialog>
        <editpop :editData="editData" :dialogVal="editDialog" @dialogChange="editChange"></editpop>
        <deldialog :appId="appId" title="是否确定要放入回收站?" @dialogChange="recChange" :dialogVal="recDialog"></deldialog>
    </div>
</template>

<script>
    import editpop from './editpop.vue'
    import {sessionSet} from '@/utils/sessionStorage.js'
    export default {
        components: {
            editpop
        },
        props: {
            appData: {
                type: Array,
                default: function () {
                    return []
                }
            },
            getApp: {
                type: Function,
                default: null
            }
        },
        data() {
            return {
                delDialog: false, //禁用的弹框
                recDialog: false, //回收站的弹框
                editDialog: false, //控制编辑弹框
                editData: {},
                appId:'', //Id
            }
        },
        methods: {
            // 点击进入平台打开新页面
            goMerchant(item) {
                console.log(item, 'item');
                sessionSet('platformId',item.id)
                // const routeData = this.$router.resolve({path: '/home'})
                // window.open(routeData.href, '_blank', )
                this.$router.push('/home')
            },
            // 禁用启用
            delInfo(item) {
                this.appId = item.id
                this.delDialog = true
            },
            delChange(type, id) {
                // 1：删除的确认 2：删除的取消
                if (type === 1) {
                    this.delDialog = false
                    this.$request({
                        url: this.$api.appMall.setstatus,
                        method: 'post',
                        data: {
                            id: id
                        }
                    }).then(res => {
                        this.getApp()
                    })
                } else if (type === 2) {
                    this.delDialog = false
                }
            },
            // 回收
            recInfo(item) {
                this.appId = item.id
                this.recDialog = true
            },
            recChange(type, id) {
                console.log(id, 'id');
                if (type === 1) {
                    this.recDialog = false
                    this.$request({
                        url: this.$api.appMall.delapp,
                        method: 'post',
                        data: {
                            id: id
                        }
                    }).then(res => {
                        this.getApp()
                    })
                } else if (type === 2) {
                    this.recDialog = false
                }
            },
            // 点击了编辑按钮
            editInfo(data) {
                this.editDialog = true
                this.editData = data
            },
            editChange(type, data) {
                console.log(type,data,'data');
                if (type === 1) {
                    this.editDialog = false
                    this.$request({
                        url: this.$api.appMall.setApp,
                        method: 'post',
                        data: {
                            id:data.id,
                            name:data.name,
                            remarks:data.remarks
                        }
                    }).then(res => {
                        this.getApp()

                    })
                } else if (type === 2) {
                    this.editDialog = false
                }
            },

        },
        computed: {

        }
    }
</script>

<style lang="scss" scoped>
    .mall-app {
        min-height: 450px;
    }

    .app-mall {
        width: calc(100%/4.5);
        height: 284px;
        border: 1px solid #999999;
        border-radius: 6px;
        margin-left: 25px;
        margin-top: 10px;

        .mall-top {
            .unexpired {
                padding: 5px 16px;
                color: #01D0B5;
                border: 1px solid #01D0B5;
                border-radius: 6px;
                background-color: #E5FFFC;
            }

            .expired {
                padding: 5px 16px;
                color: #FE725C;
                border-radius: 6px;
                background-color: #FFE6E2;
                border: 1px solid #FE725C;
            }

            .disable-btn {
                padding: 5px 16px;
                background-color: #FE725C;
                color: #FFFFFF;
                cursor: pointer;
                border-radius: 6px;
            }

            .start-up {
                padding: 5px 16px;
                background-color: #5eccb6;
                color: #FFFFFF;
                cursor: pointer;
                border-radius: 6px;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .mall-text {
        margin-top: 25px;

        div {
            height: 30px;
            line-height: 25px;
            margin-left: 24px;
        }
    }

    .enter-btn {
        margin-top: 25px;

        .btn-start {
            width: 80%;
            height: 48px;
            text-align: center;
            line-height: 48px;
            color: #ffffff;
            background-color: #4458FE;
            border-radius: 6px;
            cursor: pointer;
        }

        .btn-disable {
            width: 282px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            color: #ffffff;
            background-color: #a4adf9;
            border-radius: 6px;
            cursor: not-allowed;
        }
    }

    .mall-bommtn {
        padding: 20px 0;
        display: flex;

        .operation {
            flex: 1;
            color: #4458FE;
            cursor: pointer;
            text-align: center;

            &:before {
                content: "|";
                float: left;
                color: #999999;
            }

            &:first-child:before {
                display: none;
            }
        }


    }
</style>